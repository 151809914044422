<template>
  <div class="media-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
    </a-range-picker>
    <m-select-one
      class="search_item"
      hasOs
      :hasPosition="false"
      :allData="appList"
      v-model="searchquery.appId"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.channelIdList"
      :allData="channelList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="渠道"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appId: undefined,
        channelIdList: []
      }
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  async mounted () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    await this.getAppList()
    this.searchquery.appId = this.appList.length > 0 ? this.appList[0].id : ''
    // this.searchquery.appId = '1a13abea287bccd4'
    this.getChannelList()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间
      return (current && current < this.initDate(60, 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.media-search-container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search_item {
    padding: 10px 5px;
    width: 220px;
    display: inline-block;
  }
}
</style>
