<template>
  <div class="user-retention-chart-container">
    <div class="chart_box">
      <a-spin class="spin" :spinning="isLoading">
        <div class="chart" ref="chart"></div>
      </a-spin>
    </div>
    <div class="control">
      <div class="title">指标</div>
      <a-menu :selectedKeys="currentChecked" type="inner" class="menu_checked" @select="handleChecked">
        <a-menu-item class="menu_item" v-for="item in checkList" :key="item.value">
          <div :title="item.lable">
            <span>{{ item.lable }}</span>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { getRetentionChart, getLtvChart } from '@/api/reportdatas'
import options from './options'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      currentChecked: ['a'],
      options,
      myEcharts: null,
      isLoading: false,
      chartquery: {},
      checkList: [
        {
          lable: '留存',
          value: 'a',
          color: '#ffab31'
        },
        {
          lable: 'Ltv',
          value: 'b',
          color: '#0055ff'
        }
      ],
      dataList: [],
      lableList: []
    }
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    handleChecked ({ key }) {
      this.currentChecked = [key]
      this.getChartData()
    },
    dealquery (query) {
      this.chartquery = JSON.parse(JSON.stringify(query))
      this.getChartData()
    },
    async getChartData () {
      this.isLoading = true
      let resp = null
      if (this.currentChecked[0] === 'a') {
        resp = await getRetentionChart(this.chartquery)
      } else {
        resp = await getLtvChart(this.chartquery)
      }
      this.dataList = resp.data && resp.data.list ? resp.data.list : []
      this.lableList = resp.data ? resp.data.lable : []
      await this.changeEcharts()
      this.isLoading = false
    },
    changeEcharts () {
      this.options.series[0].name = this.currentChecked[0] === 'a' ? '留存' : 'Ltv'
      this.options.series[0].data = this.dataList.length > 0 ? this.dataList[0].data : []
      this.options.xAxis.data = this.lableList
      const color = this.checkList.find((item) => item.value === this.currentChecked[0]).color
      this.options.series[0].itemStyle.normal.color = color
      this.options.series[0].itemStyle.normal.lineStyle.color = color
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          temp.value = item.value
          str +=
            `<div style='width:100%;display:flex;justify-content:space-between'><span>` +
            item.marker +
            item.seriesName +
            ': </span>' +
            temp.value +
            `${this.currentChecked[0] === 'a' ? '%' : ''}</div>`
        })
        return params[0].axisValue + '<br>' + str
      }

      this.echartsInit()
    },
    echartsInit () {
      if (!this.$refs.chart) return
      if (this.myEcharts) {
        this.myEcharts.dispose()
        this.myEcharts = null
      }
      this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.user-retention-chart-container {
  display: flex;
  height: 300px;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  .chart_box {
    flex: 1;
    .spin {
      position: relative;
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .chart {
          height: 100%;
        }
      }
    }
  }
  .control {
    width: 250px;
    max-width: 180px;
    width: 20%;
    overflow-y: hidden;
    border-radius: 5px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .ant-menu-vertical {
      border-right: none;
    }
    .title {
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      background: #eee;
    }
    .menu_checked .menu_item {
      background: fade(@primary-color, 2%);
      height: 29px;
      line-height: 29px;
      font-size: 13px;
      margin: 3px;
      border-left: 3px solid #fff;
      &.ant-menu-item-selected {
        border-left: 3px solid;
        background: fade(@primary-color, 10%);
      }
    }
  }
}
</style>
