<template>
  <div class="users-retention-report-container">
    <UsersRetentionSearch @handleAutoSearch="handleAutoSearch" />
    <UsersRetentionChart ref="chart" />
    <UsersRetentionTable ref="table" />
  </div>
</template>

<script>
import UsersRetentionSearch from './UsersRetentionSearch'
import UsersRetentionTable from './UsersRetentionTable'
import UsersRetentionChart from './UserRetentionChart'
export default {
  components: { UsersRetentionSearch, UsersRetentionTable, UsersRetentionChart },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealquery(query)
        this.$refs.chart.dealquery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.users-retention-report-container {
  margin: 0 10px 10px 10px;
}
</style>
