<template>
  <div class="user-retention-table-container">
    <div class="control">
      <div class="indicator_radio">
        <a-radio-group v-model="currentIndicator" @change="onChangeIndicator" :disabled="loading">
          <a-radio-button value="a"> 留存 </a-radio-button>
          <a-radio-button value="b"> Ltv </a-radio-button>
        </a-radio-group>
      </div>
      <a-button
        :loading="downLoading"
        type="link"
        @click="exportRetentionReport"
        v-if="dataList.length > 0"
      ><a-icon type="download" />导出</a-button
      >
    </div>
    <div class="table">
      <a-table
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="dataList"
        :scroll="{ x: 1200 }"
        :loading="loading"
        class="rerentionTable"
        :locale="tablenodata"
        bordered
      >
        <!-- 日期 -->
        <a-table-column align="center" dataIndex="date" :width="150" :fixed="true">
          <span slot="title" class="hd_tips">日期</span>
        </a-table-column>
        <!-- 新增用户 -->
        <a-table-column align="center" dataIndex="newUser" :width="100" :fixed="true">
          <span slot="title" class="hd_tips">新增用户</span>
          <template slot-scope="newUser">
            {{ newUser ? newUser : '-' }}
          </template>
        </a-table-column>
        <!-- 新增用户占比 -->
        <a-table-column
          v-if="currentIndicator === 'b'"
          align="center"
          dataIndex="newUserRate"
          :width="140"
          :fixed="true"
        >
          <span slot="title" class="hd_tips">新增用户占比</span>
          <template slot-scope="newUserRate">
            {{ newUserRate ? newUserRate + '%' : '-' }}
          </template>
        </a-table-column>
        <!-- 动态数据 -->
        <a-table-column
          class="auto_clumn"
          v-for="(item, index) in autoClumns"
          :key="index"
          align="center"
          :dataIndex="autoClumns[index]"
          :width="100"
        >
          <span slot="title" class="hd_tips">{{ autoClumns[index] }}</span>
          <template slot-scope="value">
            <tableColumnItem :currentIndicator="currentIndicator" :value="value" />
          </template>
        </a-table-column>
      </a-table>
      <!-- <m-empty v-if="dataList.length === 0" style="height:40vh">
        <template #description>暂无数据 </template>
      </m-empty> -->
    </div>
  </div>
</template>

<script>
import { getRetentionReport, getLtvRepport } from '@/api/reportdatas'
import tableColumnItem from './tableColumnItem.vue'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
export default {
  components: { tableColumnItem },
  data () {
    return {
      currentIndicator: 'a',
      loading: false,
      downLoading: false,
      formquery: {},
      dataList: [],
      autoClumns: [],
      tablenodata: {
        emptyText: () => (
          <m-empty>
            <div slot="description">暂无数据！</div>
          </m-empty>
        )
      }
    }
  },
  methods: {
    dealquery (query) {
      this.formquery = JSON.parse(JSON.stringify(query))
      this.getTableList()
    },
    onChangeIndicator (e) {
      this.getTableList()
    },
    async getTableList () {
      this.loading = true
      let res = null
      if (this.currentIndicator === 'a') {
        res = await getRetentionReport(this.formquery)
        this.dataList = res && res.data && res.data.appUserLiucunRateVOList ? res.data.appUserLiucunRateVOList : []
      } else {
        res = await getLtvRepport(this.formquery)
        this.dataList = res && res.data && res.data.appUserLtvRateVOList ? res.data.appUserLtvRateVOList : []
      }
      await this.getAutoClumns()
      this.loading = false
    },
    // 获取相应中的key数组
    getAutoClumns () {
      if (this.dataList.length === 0) {
        this.autoClumns = []
        return
      }
      this.autoClumns = []
      let k = ''
      if (this.currentIndicator === 'a') {
        k = 'lc'
      } else {
        k = 'ltv'
      }
      for (const key in this.dataList[0]) {
        if (key.includes(k)) {
          this.autoClumns.push(key)
        }
      }
    },
    exportRetentionReport () {
      this.downLoading = true
      if (this.dataList.length === 0) return
      const wb = XLSX.utils.table_to_book(document.querySelector('.ant-table-body'))
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          `${this.currentIndicator === 'a' ? '留存报表' : 'ltv报表'}.xlsx`
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      } finally {
        setTimeout(() => {
          this.downLoading = false
        }, 1000)
      }
      return wbout
    }
  }
}
</script>

<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';
.user-retention-table-container {
  margin-top: 10px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  .control {
    display: flex;
    justify-content: space-between;
    .indicator_radio {
      .aRadioBtn();
    }
  }
  .table {
    user-select: none;
    margin: 20px;
    table tbody .auto_clumn {
      padding: 0px 0px !important;
      position: relative;
      // border: 1px solid;
    }
  }
}
</style>
