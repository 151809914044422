<template>
  <div class="table-item-container" :class="{ hasValue: value !== '' }">
    {{ value }}{{ typeof value === 'number' && currentIndicator === 'a' ? '%' : '' }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
      type: [String, Number]
    },
    currentIndicator: {
      default: '',
      type: String
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.table-item-container {
  width: 100%;
  margin: 0;
  height: 46px;
  line-height: 46px;
  border: 1px solid #fff;
  &.hasValue {
    background-color: @primary-color;
    color: #fff;
  }
}
</style>
